.base-login {
    background-color: #F7FFF7;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    min-height: 100vh;
    width: 100%;
}

.otolithics-logo{
    width: 300px;
    height: 150px;
}

@media (min-width: 361px) and ( max-width: 576px) {
    .otolithics-logo {
        width: 80%;
        height: 80% !important;
    }

    .base-button,
    .base-text-description,
    .room-field{
        width: 216px !important;
    }
}

@media (max-width: 360px) {
    .otolithics-logo {
        width: 60% !important;
        height: 60% !important;
    }

    .base-button,
    .base-text-description,
    .room-field{
        width: 216px !important;
    }
}

@media screen and (orientation: landscape) {
    .base-login {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .left-content,
    .right-content {
        display: flex;
        flex-direction: column;
    }
    
    .left-content {
        justify-content: flex-start;
        order: 1 !important; /* Order left content to appear first */
        margin-left: 20px;
        margin-right: 20px;
    }

    .right-content {
        justify-content: flex-end;
        order: 2 !important; /* Order right content to appear second */
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (orientation: portrait) {
    .otolithics-logo {
        margin-bottom: 100px;
        width: 80% !important;
    }
    
    .left-content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.room-field{
    width: 320px;
    margin-left: 10px !important;
}

.otolithics-qrcode img,
.otolithics-logo img{
    width: 100%;
}

.otolithics-qrcode{
    margin: 20px;
    width: 160px;
    height: 160px;
    background: white;
}

.base-text
.base-text-typography {
    font-weight: 400;
    text-align: center;
    font-size: 24px;
    color:#FF4C00;
}

.base-text-typography.h1{
    font-weight: 500;
    font-size: 28px;
}

.base-button,
.base-text-description{
    margin: 10px;
    width: 320px;
    height: 84px;
    text-align: center;
}

.base-text-typography.description{
    font-size: 14px;
    width: 100%;
    height: 100%;
    color: #2F2D2E;
}

.qr-code-button{
    width: 100%;
    background-color: #FF4C00 !important;
}

.qr-code-button.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled{
    color: #FFFFFF;
    background-color: lightgray !important;
}

.logout-button{
    width: 100%;
    margin-top: 15px !important ;
    background-color: #FFFFFF !important;
    color: #FF4C00 !important;
    border: #FF4C00 1px solid !important;
}