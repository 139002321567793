.base-exam {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  width: 100vw;
  height: 100vh;
}

div#root {
  background: black;
}

@media screen and (orientation: portrait) {
  .base-exam {
    rotate: 90deg;
  }

  .exam-container {
    width: 100vw !important;
    height: 100vh !important;
  }
}

.exam-letters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exam-container-wrapper {
  display: flex;
  justify-content: space-between; /* Isso irá alinhar as divs lado a lado com espaço entre elas */
  background-color: black; /* Ajuste a cor de fundo conforme necessário */
  width: 100vw;
  height: 100vh;
  /* Outros estilos apropriados, como alinhamento de conteúdo, margens, etc. */
}

.exam-container {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 90%;
  border-radius: 100px;
}

.rotation-clockwise {
  animation: rotate-clockwise 100s linear infinite;
}

.rotation-anticlockwise {
  animation: rotate-anticlockwise 100s linear infinite;
}

.inner-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  z-index: 1;
  transform: translateX(-50%); /* Adjust position to center correctly */
  width: 3px; /* Make the line 3px wide */
  height: 30%; /* Adjust the height to make it visible */
  background-color: red; /* Color the line red */
  position: absolute;
}

@keyframes rotate-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-anticlockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
