.base-login {
    background-color: #F7FFF7;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    min-height: 100vh;
    width: 100%;
}

.otolithics-logo{
    width: 300px;
    height: 150px;
    margin-bottom: 20px;
}

.otolithics-logo img{
    width: 100%;
}

@media (min-width: 361px) and ( max-width: 576px) {
    .otolithics-logo {
        width: 80%;
        height: 80%;
    }

    .base-button,
    .base-text-description{
        width: 216px !important;
    }

    .base-text,
    .base-text-typography {
        font-size: 20px !important;
    }
}

@media (max-width: 360px) {
    .otolithics-logo {
        width: 60% !important;
        height: 60% !important;
    }

    .base-button,
    .base-text-description,
    .room-field{
        width: 216px !important;
    }
}

.base-text
.base-text-typography {
    font-weight: 400;
    text-align: center;
    font-size: 24px;
    color:#2F2D2E;
}

.base-text-typography.h1{
    font-weight: 500;
    font-size: 28px;
}

.base-button,
.base-text-description{
    margin: 10px;
    width: 320px;
    height: 84px;
    text-align: center;
}

.base-text-typography.description{
    font-size: 14px;
    width: 100%;
    height: 100%;
}

.qr-code-button{
    width: 100%;
    background-color: #FF4C00 !important;
}

.logout-button{
    width: 100%;
    margin-top: 15px !important ;
    background-color: #FFFFFF !important;
    color: #FF4C00 !important;
    border: #FF4C00 1px solid !important;
}